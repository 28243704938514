import SchoolList from "./Components/SchoolList";
import HomePage from "./Pages/HomePage";


function App() {
  return (
    <div className="App">
      {/* <SchoolList /> */}
      <HomePage />
    </div>
  );
}

export default App;
